import { SecurityContext, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthGuard, AuthService } from '@auth0/auth0-angular';
import { map, of, switchMap, take, tap } from 'rxjs';

export const parentAuthGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authGuard = inject(AuthGuard);
  const sanitizer = inject(DomSanitizer);

  return inject(AuthService).isAuthenticated$.pipe(
    switchMap((authenticated) => {
      if (authenticated) {
        return authGuard.canActivate(route, state);
      } else {
        const returnUrl = window.location.href;
        const url = sanitizer.sanitize(SecurityContext.URL, returnUrl);

        if (url) {
          const splitUrl = extractAfterThirdSlash(url);
          sessionStorage.setItem('returnUrl', splitUrl);
        }

        router.navigate(['login']);
        return of(true);
      }
    }),
    take(1)
  );
};

function extractAfterThirdSlash(url: string) {
  const parts = url.split('/');

  // Check if there are at least four parts (which means at least three slashes)
  if (parts.length < 4) {
    throw new Error('URL does not contain three slashes.');
  }

  // Join the parts back together, starting from the fourth element (index 3)
  return parts.slice(3).join('/');
}

export const determineAuthRedirectTo: CanActivateFn = () => {
  const router = inject(Router);
  const auth0Service = inject(AuthService);

  return auth0Service.isAuthenticated$.pipe(
    tap((isAuthenticated) => {
      if (!isAuthenticated) {
        router.navigate(['login'], { replaceUrl: true });
      } else {
        router.navigate(['docs']);
      }
    }),
    map(() => true),
    take(1)
  );
};

export const determineRedirectTo: CanActivateFn = () => {
  const router = inject(Router);
  const auth0Service = inject(AuthService);

  return auth0Service.isAuthenticated$.pipe(
    tap((isAuthenticated) => {
      if (!isAuthenticated) {
        router.navigate(['login']);
      } else {
        router.navigate(['docs']);
      }
    }),
    map(() => true),
    take(1)
  );
};
