import { Routes } from '@angular/router';
import { determineAuthRedirectTo, determineRedirectTo, parentAuthGuardFn } from './shared/guards/auth.guards';

export const routes: Routes = [  
  {
    path: 'login',
    loadComponent: () =>
      import('./features/login/login.component').then(
        (c) => c.AxleLoginComponent
      ),
  },
    {
    path: 'docs',
    loadComponent: () =>
      import('./features/docs/docs.component').then(
        (c) => c.DocsComponent
      ),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'auth',
    children: [],
    // component: SpinnerOverlayComponent,
    canActivate: [determineAuthRedirectTo]
  },
  {
    path: '**',
    pathMatch: 'full',
    children: [],
    canActivate: [determineRedirectTo]
  }]
